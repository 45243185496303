import logo from "./resources/img/boko-logo.png";
import instagram from "./resources/img/instagram.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <main>
        <div className="container">
          <img src={logo} alt="boko logo" />
          <h1>Coming soon</h1>
          <p>Сайт у стадії розробки.</p>
          <p>Будь ласка, перейдіть в наш магазин Instagram</p>
          <a href="https://www.instagram.com/boko_kyiv/">
            <button className="btn">
              <img src={instagram} alt="instagram logo"/>
              Instagram
            </button>
          </a>
        </div>
      </main>
    </div>
  );
}

export default App;
